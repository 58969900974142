<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="region"
              :items="regiones"
              @change="getProvincias"
              item-key="id_region"
              item-text="region"
              return-object
              label="Region"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="provincia"
              :items="provincias"
              @change="getComunas"
              item-key="id_provincia"
              item-text="provincia"
              return-object
              label="Provincia"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="comuna"
              :items="comunas"
              item-key="id_comuna"
              item-text="comuna"
              return-object
              label="Comuna"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on:click="cleanFilters()"
            >
              Limpiar Filtros
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="competencia"
              :items="competenciasFilter"
              item-key="id"
              item-text="competencia"
              return-object
              multiple
              label="Competencia"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="grupo"
              :items="grupos"
              item-key="id"
              item-text="grupo"
              return-object
              multiple
              label="Grupo"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="externo"
              :items="externos"
              item-key="id"
              item-text="externo"
              return-object
              label="Externo"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on:click="getJobbersFiltered()"
            >
              Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-data-table
      v-model="jobberSelected"
      :headers="headersJobbers"
      :items="jobbers"
      :search="searchJobber"
      :item-class="jobberRowSelected"
      @click:row="showCoverage"
      class="table-striped elevation-1 mb-8 rounded"
      id="jobber_table"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded">
          <v-toolbar-title>Jobbers: {{ jobbers.length }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="searchJobber"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-btn
            color="primary float-right"
            dark
            class="mb-2 ml-5"
            v-on:click="
              downloadExcelFile('Resultado Cobertura Jobber', 'jobber_table')
            "
          >
            Exportar
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
    <draggable class="row" :sort="true">
      <v-col cols="6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Cobertura: {{ coberturas.length }}</h3>
            </div>
            <v-icon class="handle cursor-pointer">drag_handle</v-icon>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-text-field
                  v-model="searchCobertura"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersCobertura"
                  :items="coberturas"
                  :search="searchCobertura"
                  disable-pagination
                  hide-default-footer
                  class="table-striped elevation-1"
                  id="cobertura_table"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Competencias: {{ competencias.length }}
              </h3>
            </div>
            <v-icon class="handle cursor-pointer">drag_handle</v-icon>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-text-field
                  v-model="searchCompetencia"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersCompetencia"
                  :items="competencias"
                  :search="searchCompetencia"
                  item-key="key"
                  disable-pagination
                  hide-default-footer
                  class="table-striped elevation-1"
                  id="competencia_table"
                >
                  <template slot="body.append">
                    <tr
                      class="font-weight-bolder"
                      v-if="Object.keys(competencias).length > 0"
                    >
                      <td>Total</td>
                      <td class="text-center">
                        {{ competencias.reduce((s, a) => s + a.puntaje, 0) }}
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Capacitación: {{ capacitaciones.length }}
              </h3>
            </div>
            <v-icon class="handle cursor-pointer">drag_handle</v-icon>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-text-field
                  v-model="searchCapacitacion"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersCapacitacion"
                  :items="capacitaciones"
                  :search="searchCapacitacion"
                  item-key="key"
                  disable-pagination
                  hide-default-footer
                  class="table-striped elevation-1"
                  id="capacitacion_table"
                >
                  <template slot="body.append">
                    <tr
                      class="font-weight-bolder"
                      v-if="Object.keys(capacitaciones).length > 0"
                    >
                      <td>Total</td>
                      <td class="text-center">
                        {{ capacitaciones.reduce((s, a) => s + a.puntaje, 0) }}
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Antecedentes: {{ antecedentes.length }}
              </h3>
            </div>
            <v-icon class="handle cursor-pointer">drag_handle</v-icon>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-text-field
                  v-model="searchAntecendente"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersAntecedente"
                  :items="antecedentes"
                  :search="searchAntecendente"
                  item-key="key"
                  disable-pagination
                  hide-default-footer
                  class="table-striped elevation-1"
                  id="antecedente_table"
                >
                  <template slot="body.append">
                    <tr
                      class="font-weight-bolder"
                      v-if="Object.keys(antecedentes).length > 0"
                    >
                      <td>Total</td>
                      <td class="text-center">
                        {{ antecedentes.reduce((s, a) => s + a.puntaje, 0) }}
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Ficha del Jobber</h3>
            </div>
            <v-icon class="handle cursor-pointer">drag_handle</v-icon>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row v-if="dialogShowCoverage">
              <v-col cols="6">
                <div>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Jobber: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].nombre }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> RUT: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].rut }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Celular: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].telefono }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Correo: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].correo }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Grupo: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].grupo }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Externo: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].externo }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Puntaje: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].puntaje }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Asignadas: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].tareasAsignadas }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Realizadas: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].tareasRealizadas }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> En Proceso: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].tareasPendientes }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Dirección: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].direccion }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">
                        Nivel de Estudio:
                      </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].estudio }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Profesión: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].profesion }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">
                        Fecha Inscripción:
                      </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].fecha_creacion }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Vigente hasta: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].vigente_hasta }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">
                        Fecha de Nacimiento:
                      </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].fecha_nacimiento }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Observaciones: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].observacion }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"> Inscripción: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].fecha_inscripcion }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">
                        Paso 2 / Antecedentes:
                      </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].fecha_antecedentes }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Firma Contrato: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ jobberSelected[0].fecha_contrato }}
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="6">
                <TaskMap
                  ref="jobber_map"
                  :aJobs="aJobs"
                  :bCircle="false"
                  map="jobber_map"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </draggable>
  </div>
</template>

<script>
import { _ } from "vue-underscore";
import draggable from "vuedraggable";
import "moment/locale/es";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { GET_REGIONES } from "@/core/services/store/global.module";
import {
  GET_USUARIO_GRUPOS,
  GET_COMPETENCIAS,
  GET_JOBBERS_FILTERED,
  GET_ALL_JOBBER_DATA,
} from "@/core/services/store/jobber.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
import TaskMap from "@/view/components/task/TaskMap.vue";
export default {
  name: "jobberCoverage",
  components: {
    draggable,
    TaskMap,
  },
  data() {
    return {
      region: null,
      regiones: [],
      provincia: null,
      provincias: [],
      comuna: null,
      comunas: [],
      grupos: [],
      grupo: null,
      competenciasFilter: [],
      competencia: null,
      externos: [
        {
          id: "",
          externo: "",
        },
        {
          id: "1",
          externo: "Si",
        },
        {
          id: "2",
          externo: "No",
        },
      ],
      externo: null,
      headersJobbers: [
        {
          text: "ID",
          value: "uniqueID",
          align: "center",
          width: "5em",
        },
        {
          text: "Jobber",
          value: "nombre",
          width: "16em",
        },
        {
          text: "RUT",
          value: "rut",
          width: "9em",
        },
        {
          text: "Celular",
          value: "telefono",
          align: "center",
          width: "8em",
        },
        {
          text: "Correo",
          value: "correo",
          width: "8em",
        },
        {
          text: "Grupo",
          value: "grupo",
          width: "8em",
        },
        {
          text: "Externo",
          value: "externo",
          align: "center",
          width: "8em",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
          width: "8em",
        },
        {
          text: "Asignadas",
          value: "tareasAsignadas",
          align: "center",
          width: "10em",
        },
        {
          text: "Realizadas",
          value: "tareasRealizadas",
          align: "center",
          width: "10em",
        },
        {
          text: "En Proceso",
          value: "tareasPendientes",
          align: "center",
          width: "10em",
        },
      ],
      jobbers: [],
      searchJobber: "",
      jobberSelected: [],
      headersCobertura: [
        {
          text: "Comuna",
          value: "comuna",
        },
        {
          text: "Región",
          value: "region",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
      ],
      coberturas: [],
      searchCobertura: "",
      headersCompetencia: [
        {
          text: "Competencia",
          value: "competencia",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
      ],
      competencias: [],
      searchCompetencia: "",
      headersCapacitacion: [
        {
          text: "Capacitación",
          value: "capacitacion",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
      ],
      capacitaciones: [],
      searchCapacitacion: "",
      headersAntecedente: [
        {
          text: "Antecedente",
          value: "antecedente",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
      ],
      antecedentes: [],
      searchAntecendente: "",
      dialogShowCoverage: false,
      aJobs: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cobertura Jobber", route: "jobber-coverage" },
    ]);
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getRegiones();
      this.getUsuarioGrupos();
      this.getCompetencias();
      // this.getJobbersFiltered();
    },
    getRegiones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_REGIONES).then((regiones) => {
        var aRegiones = _.chain(regiones)
          .groupBy("id_region")
          .map(function (value, index) {
            return {
              id_region: index,
              region: value[0].region,
              provincias: value,
            };
          })
          .value();
        aRegiones.unshift({ id_region: "", region: "" });
        oVue.regiones = aRegiones;
        KTUtil.removeLoading();
      });
    },
    getProvincias() {
      let oVue = this;
      if (oVue.region.id_region != "") {
        let aProvincias = _.chain(oVue.region.provincias)
          .groupBy("id_provincia")
          .map(function (value, index) {
            return {
              id_provincia: index,
              provincia: value[0].provincia,
              comunas: value,
            };
          })
          .value();
        aProvincias.unshift({ id_provincia: null, provincia: "" });
        oVue.provincias = aProvincias;
      } else {
        oVue.provincias = [];
        oVue.comunas = [];
        oVue.provincia = null;
        oVue.comuna = null;
      }
    },
    getComunas() {
      let oVue = this;
      if (oVue.provincia.id_provincia != "") {
        let aComunas = _.chain(oVue.provincia.comunas)
          .groupBy("id_comuna")
          .map(function (value, index) {
            return {
              id_comuna: index,
              comuna: value[0].comuna,
            };
          })
          .value();
        aComunas.unshift({ id_comuna: null, comuna: "" });
        oVue.comunas = aComunas;
      } else {
        oVue.comunas = [];
        oVue.comuna = null;
      }
    },
    getUsuarioGrupos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_GRUPOS).then((grupos) => {
        oVue.grupos = grupos;
        KTUtil.removeLoading();
      });
    },
    getCompetencias() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_COMPETENCIAS).then((competencias) => {
        oVue.competenciasFilter = competencias;
        KTUtil.removeLoading();
      });
    },
    cleanFilters() {
      let oVue = this;
      oVue.region = null;
      oVue.provincia = null;
      oVue.comuna = null;
      oVue.competencia = null;
      oVue.grupo = null;
      oVue.externo = null;
      oVue.provincias = [];
      oVue.comunas = [];
    },
    async getJobbersFiltered() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.hideCoverage();
      const region =
        oVue.region && oVue.region.id_region != ""
          ? oVue.region.id_region
          : null;
      const provincia =
        oVue.provincia && oVue.provincia.id_provincia != ""
          ? oVue.provincia.id_provincia
          : null;
      const comuna =
        oVue.comuna && oVue.comuna.id_comuna != ""
          ? oVue.comuna.id_comuna
          : null;
      const grupo =
        oVue.grupo && Object.keys(oVue.grupo).length > 0
          ? oVue.grupo
              .map(function (a) {
                return a.id;
              })
              .join(", ")
          : null;
      const competencia =
        oVue.competencia && Object.keys(oVue.competencia).length > 0
          ? oVue.competencia
              .map(function (a) {
                return a.id;
              })
              .join(", ")
          : null;
      const externo =
        oVue.externo && oVue.externo.id != "" ? oVue.externo.id : null;

      oVue.jobbers = [];
      let aData = {
        region,
        provincia,
        comuna,
        grupo,
        competencia,
        externo,
      };
      oVue.$store.dispatch(GET_JOBBERS_FILTERED, aData).then((jobbers) => {
        //eslint-disable-next-line
        oVue.jobbers = jobbers.map(({ selected, ...jobber }) => ({
          ...jobber,
          selected: false,
        }));
        if (jobbers.length == 1) {
          oVue.jobberSelected = oVue.jobbers;
        }
        KTUtil.removeLoading();
      });
    },
    async showCoverage(jobberSelected) {
      let oVue = this;
      oVue.selectJobber(jobberSelected);
      if (typeof jobberSelected !== "undefined") {
        const aAllJobberDataResponse = await oVue.getAllJobberData(
          jobberSelected.uniqueID
        );
        if (aAllJobberDataResponse.result) {
          const aCoberturas =
            typeof aAllJobberDataResponse.data.coberturas !== "object"
              ? JSON.parse(aAllJobberDataResponse.data.coberturas)
              : aAllJobberDataResponse.data.coberturas;
          const aCompetencias =
            typeof aAllJobberDataResponse.data.competencias !== "object"
              ? JSON.parse(aAllJobberDataResponse.data.competencias)
              : aAllJobberDataResponse.data.competencias;
          const aCapacitaciones =
            typeof aAllJobberDataResponse.data.capacitaciones !== "object"
              ? JSON.parse(aAllJobberDataResponse.data.capacitaciones)
              : aAllJobberDataResponse.data.capacitaciones;
          const aAntecedentes =
            typeof aAllJobberDataResponse.data.antecedentes !== "object"
              ? JSON.parse(aAllJobberDataResponse.data.antecedentes)
              : aAllJobberDataResponse.data.antecedentes;

          oVue.coberturas = aCoberturas;
          oVue.competencias = aCompetencias;
          oVue.capacitaciones = aCapacitaciones;
          oVue.antecedentes = aAntecedentes;
          const aJobs = [
            {
              latitud: jobberSelected.latitud,
              longitud: jobberSelected.longitud,
              idEstado: 1,
              type: "task",
            },
          ];
          oVue.aJobs = aJobs;
          oVue.dialogShowCoverage = true;
          setTimeout(() => {
            oVue.initJobbersMap();
          }, 500);
        }
      } else {
        oVue.hideCoverage();
      }
    },
    hideCoverage() {
      let oVue = this;
      oVue.dialogShowCoverage = false;
      oVue.jobberSelected = [];
      oVue.coberturas = [];
      oVue.competencias = [];
      oVue.capacitaciones = [];
      oVue.antecedentes = [];
    },
    async getAllJobberData(jobber) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobberDataResponse = await oVue.$store.dispatch(
        GET_ALL_JOBBER_DATA,
        jobber
      );
      KTUtil.removeLoading();
      return aAllJobberDataResponse;
    },
    initJobbersMap() {
      let oVue = this;
      oVue.$refs.jobber_map.initJobsMap();
    },
    downloadExcelFile(filename, id) {
      let oVue = this;
      if (Object.keys(oVue.jobbers).length == 0) {
        Swal.fire({
          title: "Error",
          text: "No hay datos para exportar",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      const region =
        oVue.region && oVue.region.region != "" ? oVue.region.region : "Todos";
      const provincia =
        oVue.provincia && oVue.provincia.provincia != ""
          ? oVue.provincia.provincia
          : "Todos";
      const comuna =
        oVue.comuna && oVue.comuna.comuna != "" ? oVue.comuna.comuna : "Todos";
      const grupo =
        oVue.grupo && Object.keys(oVue.grupo).length > 0
          ? oVue.grupo
              .map(function (a) {
                return a.grupo;
              })
              .join(", ")
          : "Todos";
      const competencia =
        oVue.competencia && Object.keys(oVue.competencia).length > 0
          ? oVue.competencia
              .map(function (a) {
                return a.competencia;
              })
              .join(", ")
          : "Todos";
      const externo =
        oVue.externo && oVue.externo.externo != ""
          ? oVue.externo.externo
          : "Todos";

      const infoFilters = [
        ["Región", region],
        ["Provincia", provincia],
        ["Comuna", comuna],
        ["Competencia", competencia],
        ["Grupo", grupo],
        ["Externo", externo],
      ];

      const ws_sheet_name = "filtros";
      const ws_sheet = XLSX.utils.aoa_to_sheet(infoFilters);
      wb.SheetNames.push(ws_sheet_name);
      wb.Sheets[ws_sheet_name] = ws_sheet;

      const elt = KTUtil.getById(id);
      const table = elt.querySelector("table");
      const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
        raw: true,
      }));
      ws["!cols"] = [];
      ws["!cols"][0] = { hidden: true };
      const cols = oVue.headersJobbers.length;
      const row = 0;
      for (let col = 0; col <= cols; col++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[ref]) {
          ws[ref].s = {
            font: {
              bold: true,
            },
          };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, filename);

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
    jobberRowSelected: function (item) {
      return item.selected
        ? "v-data-table__selected cursor-pointer"
        : "cursor-pointer";
    },
    selectJobber(jobberSelected) {
      let oVue = this;
      //eslint-disable-next-line
      oVue.jobbers = oVue.jobbers.map(({ selected, ...jobber }) => ({
        ...jobber,
        selected: false,
      }));
      const jobberSelectedIndex = oVue.jobbers.findIndex(
        (j) => j.uniqueID == jobberSelected.uniqueID
      );
      oVue.jobbers[jobberSelectedIndex].selected = true;
      oVue.jobberSelected[0] = jobberSelected;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
};
</script>
